import 'gestalt/dist/gestalt.css';
import 'gestalt-datepicker/dist/gestalt-datepicker.css';
import './index.css';
import {lazy, StrictMode, Suspense} from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter} from 'react-router-dom';
import reportWebVitals from './reportWebVitals';
import {AuthProvider, useAuth} from './auth';
import FullPageSpinner from './components/FullPageSpinner';

const AuthenticatedApp = lazy(() => import('./App'));
const UnauthenticatedApp = lazy(() => import('./UnauthenticatedApp'));

function Root() {
  const {isAuthenticated} = useAuth();
  return isAuthenticated ? <AuthenticatedApp /> : <UnauthenticatedApp />;
}

ReactDOM.render(
  <StrictMode>
    <BrowserRouter>
      <Suspense fallback={<FullPageSpinner />}>
        <AuthProvider>
          <Root />
        </AuthProvider>
      </Suspense>
    </BrowserRouter>
  </StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
