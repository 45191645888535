import {createContext, useContext, useEffect, useState} from 'react';
import FullPageSpinner from './components/FullPageSpinner';
import {
  authenticate,
  logout as logoutApi,
  verifyAuthenticationToken,
} from './api';

const AuthContext = createContext(
  {} as {isAuthenticated: boolean; login: Function; logout: Function}
);

function AuthProvider({children}: any) {
  const [firstAttemptFinished, setFirstAttemptFinished] =
    useState<boolean>(false);
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);

  useEffect(() => {
    verifyAuthenticationToken()
      .then(
        () => setIsAuthenticated(true),
        async error => error?.response?.status === 401 && (await logout())
      )
      .then(() => setFirstAttemptFinished(true));
  }, []);

  async function login({email, password}: {email: string; password: string}) {
    return authenticate({email, password}).then(() => setIsAuthenticated(true));
  }

  async function logout() {
    await logoutApi();
    setIsAuthenticated(false);
  }

  if (!firstAttemptFinished) {
    return <FullPageSpinner />;
  }

  return (
    <AuthContext.Provider value={{isAuthenticated, login, logout}}>
      {children}
    </AuthContext.Provider>
  );
}

function useAuth() {
  const context = useContext(AuthContext);
  if (context !== undefined) {
    return context;
  }
  throw new Error('useAuth must be used within AuthProvider');
}

export {AuthProvider, useAuth};
