import * as qs from './util/qs';

async function req(endpoint: string = '', {body, ...init}: any = {}) {
  let contentType = undefined;
  if (body && !(body instanceof FormData)) {
    contentType = 'application/json';
    body = JSON.stringify(body);
  }
  if (body && body instanceof FormData) {
    body.append('_method', 'PATCH');
    if (init?.method) {
      init.method = 'POST';
    }
  }
  try {
    const token = getToken();
    const url = `${process.env.REACT_APP_DASHBOARD_BE_API_BASE_URL}/${endpoint}`;
    const response = await window.fetch(url, {
      method: body ? 'POST' : 'GET',
      ...init,
      headers: {
        Accept: 'application/json',
        ...(contentType && {'Content-Type': contentType}),
        ...(token && {Authorization: `Bearer ${token}`}),
        ...init.headers,
      },
      ...(body && {body: body}),
    });
    if (!response.ok) {
      return Promise.reject({response});
    }
    return response;
  } catch (error: any) {
    return Promise.reject({unexpectedErrorMessage: error?.message});
  }
}

function setToken(token: string) {
  window.localStorage.setItem('jwt', token);
}

function getToken() {
  return window.localStorage.getItem('jwt');
}

async function authenticate({
  email,
  password,
}: {
  email: string;
  password: string;
}): Promise<void> {
  const response = await req(`login`, {
    body: {
      email,
      password,
    },
  });
  const result = await response.json();
  if (response.ok && result?.token) {
    setToken(result?.token);
    return;
  }
  throw new Error(
    "Couldn't sign in because of a server error. Try signing in again."
  );
}

async function logout(): Promise<void> {
  window.localStorage.removeItem('jwt');
}

function wrap<T>(promise: any) {
  let status = 'pending';
  let result: any;
  let suspender = promise.then(
    (r: any) => {
      status = 'success';
      result = r;
    },
    (e: any) => {
      status = 'error';
      result = e;
    }
  );
  return {
    read(): T {
      if (status === 'pending') {
        throw suspender;
      } else if (status === 'error') {
        throw result;
      }
      return result;
    },
  };
}

async function verifyAuthenticationToken(): Promise<Response> {
  return req();
}

async function getEmailAnalyticsPixelReporting({filters}: {filters?: any}) {
  const params = qs.params({
    ...(filters && {filters}),
  });
  const response = await req(`email-analytics/reporting/pixel?${params}`);
  return await response.json();
}

interface PixelReportingItem {
  campaign: {
    name: string;
  };
  tag: {
    label: string;
  };
  recipients: number;
  delivered: number;
  opens: number;
  clicks: number;
  items: number;
  add_to_totes: number;
  orders: number;
  sales: number;
  items_matching_sales_order: number;
  orders_matching_sales_order: number;
  sales_matching_sales_order: number;
  first_occurrence_at: number;
  last_update_at: number | null;
}

function fetchEmailAnalyticsPixelReporting({
  filters,
}: {
  filters?: any;
  paging?: {after?: number; pageSize?: number};
}) {
  return wrap<{
    total_records: number;
    items: PixelReportingItem[];
  }>(getEmailAnalyticsPixelReporting({filters}));
}

async function getEmailAnalyticsCampaigns() {
  const response = await req(`email-analytics/campaigns`);
  return await response.json();
}

interface Campaign {
  id: number;
  name: string;
}

function fetchEmailAnalyticsCampaigns() {
  return wrap<{items: Campaign[]}>(getEmailAnalyticsCampaigns());
}

async function getEmailAnalyticsTags() {
  const response = await req(`email-analytics/tags`);
  return await response.json();
}

interface Tag {
  id: number;
  label: string;
}

function fetchEmailAnalyticsTags() {
  return wrap<{items: Tag[]}>(getEmailAnalyticsTags());
}

async function getEmailAnalyticsSendgridEvents({
  event,
  paging,
  filters,
}: {
  event?: any;
  paging?: any;
  filters?: any;
}) {
  const params = qs.params({
    event,
    ...(paging && {paging}),
    ...(filters && {filters}),
  });
  const response = await req(`email-analytics/sendgrid-events?${params}`);
  return await response.json();
}

async function generateSendgridEventsExport({
  event,
  filters,
}: {
  event?: any;
  filters?: any;
}) {
  const params = qs.params({
    event,
    ...(filters && {filters}),
  });
  const response = await req(`email-analytics/generate-sendgrid-events-export?${params}`);
  return await response.json();
}

function generateOpenSendgridEventsExport({filters}: {filters?: any} = {}) {
  return wrap<any>(
    generateSendgridEventsExport({event: 'open', filters})
  );
}

function generateClickSendgridEventsExport({filters}: {filters?: any} = {}) {
  return wrap<any>(
    generateSendgridEventsExport({event: 'click', filters})
  );
}

function generateSpamReportSendgridEventsExport({filters}: {filters?: any} = {}) {
  return wrap<any>(
    generateSendgridEventsExport({event: 'spamreport', filters})
  );
}

function generateDroppedSendgridEventsExport({filters}: {filters?: any} = {}) {
  return wrap<any>(
    generateSendgridEventsExport({event: 'dropped', filters})
  );
}

function generateBounceSendgridEventsExport({filters}: {filters?: any} = {}) {
  return wrap<any>(
    generateSendgridEventsExport({event: 'bounce', filters})
  );
}

function fetchEmailAnalyticsOpenSendgridEvents({
  paging,
  filters,
}: {paging?: any; filters?: any} = {}) {
  return wrap<any>(
    getEmailAnalyticsSendgridEvents({event: 'open', paging, filters})
  );
}

function fetchEmailAnalyticsClickSendgridEvents({
  paging,
  filters,
}: {paging?: any; filters?: any} = {}) {
  return wrap<any>(
    getEmailAnalyticsSendgridEvents({event: 'click', paging, filters})
  );
}

function fetchEmailAnalyticsSpamReportSendgridEvents({
  paging,
  filters,
}: {paging?: any; filters?: any} = {}) {
  return wrap<any>(
    getEmailAnalyticsSendgridEvents({event: 'spamreport', paging, filters})
  );
}

function fetchEmailAnalyticsUnsubscribeSendgridEvents({
  paging,
  filters,
}: {paging?: any; filters?: any} = {}) {
  return wrap<any>(
    getEmailAnalyticsSendgridEvents({event: 'unsubscribe', paging, filters})
  );
}

function fetchEmailAnalyticsDroppedSendgridEvents({
  paging,
  filters,
}: {paging?: any; filters?: any} = {}) {
  return wrap<any>(
    getEmailAnalyticsSendgridEvents({event: 'dropped', paging, filters})
  );
}

function fetchEmailAnalyticsDeliveredSendgridEvents({
  paging,
  filters,
}: {paging?: any; filters?: any} = {}) {
  return wrap<any>(
    getEmailAnalyticsSendgridEvents({event: 'delivered', paging, filters})
  );
}

function fetchEmailAnalyticsBounceSendgridEvents({
  paging,
  filters,
}: {paging?: any; filters?: any} = {}) {
  return wrap<any>(
    getEmailAnalyticsSendgridEvents({event: 'bounce', paging, filters})
  );
}

async function getSignUpsSources() {
  const response = await req(`sign-ups/sources`);
  return await response.json();
}

function fetchSignUpsSources() {
  return wrap<{
    items: {
      id: number;
      label: string;
    }[];
  }>(getSignUpsSources());
}

async function getSignUpsEmails({
  filters,
  paging,
}: {
  filters?: any;
  paging?: any;
}) {
  const params = qs.params({
    ...(filters && {filters}),
    ...(paging && {paging}),
  });
  const response = await req(`sign-ups/emails?${params}`);
  return await response.json();
}

function fetchSignUpsEmails({
  filters,
  paging,
}: {
  filters?: any;
  paging?: {after?: number; pageSize?: number};
}) {
  return wrap<{
    total_records: number;
    items: {
      id: string;
      email: string;
      timestamp: number;
    }[];
  }>(getSignUpsEmails({filters, paging}));
}

async function downloadSignUpsEmailsAsCSV({filters}: {filters?: any}) {
  const params = qs.params({
    ...(filters && {filters}),
  });
  const response = await req(`sign-ups/emails?${params}`, {
    headers: {
      Accept: 'text/csv',
    },
  });
  const blob = await response.blob();
  const downloadLink = document.createElement('a');
  downloadLink.href = window.URL.createObjectURL(blob);
  downloadLink.download = 'emails.csv';
  downloadLink.click();
}

function downloadExportHref(filename: string) {
  return `${process.env.REACT_APP_DASHBOARD_BE_API_BASE_URL}/download-export?filename=${filename}`;
}

export {
  authenticate,
  logout,
  verifyAuthenticationToken,
  // EmailAnalytics
  generateOpenSendgridEventsExport,
  generateClickSendgridEventsExport,
  generateSpamReportSendgridEventsExport,
  generateDroppedSendgridEventsExport,
  generateBounceSendgridEventsExport,
  fetchEmailAnalyticsPixelReporting,
  fetchEmailAnalyticsCampaigns,
  fetchEmailAnalyticsTags,
  fetchEmailAnalyticsOpenSendgridEvents,
  fetchEmailAnalyticsClickSendgridEvents,
  fetchEmailAnalyticsSpamReportSendgridEvents,
  fetchEmailAnalyticsUnsubscribeSendgridEvents,
  fetchEmailAnalyticsDroppedSendgridEvents,
  fetchEmailAnalyticsDeliveredSendgridEvents,
  fetchEmailAnalyticsBounceSendgridEvents,
  fetchSignUpsSources,
  fetchSignUpsEmails,
  downloadSignUpsEmailsAsCSV,
  downloadExportHref
};
