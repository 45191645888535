import {Box, Layer, Spinner} from 'gestalt';

function FullPageSpinner() {
  return (
    <Layer>
      <Box
        color="transparentDarkGray"
        position="fixed"
        top
        left
        right
        bottom
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Spinner accessibilityLabel="Waiting for content to load" show={true} />
      </Box>
    </Layer>
  );
}

export default FullPageSpinner;
