/**
 * @see https://github.com/knowledgecode/jquery-param/blob/master/src/index.js
 */

function add(container: string[], key: string, value: any) {
  value = typeof value === 'function' ? value() : value;
  value = value === null ? '' : value === undefined ? '' : value;
  container[container.length] =
    encodeURIComponent(key) + '=' + encodeURIComponent(value);
}

function buildParams(container: string[], prefix: string, obj: any): string[] {
  var i, len, key;
  if (prefix) {
    if (Array.isArray(obj)) {
      for (i = 0, len = obj.length; i < len; i++) {
        buildParams(
          container,
          prefix + '[' + (typeof obj[i] === 'object' && obj[i] ? i : '') + ']',
          obj[i]
        );
      }
    } else if (Object.prototype.toString.call(obj) === '[object Object]') {
      for (key in obj) {
        buildParams(container, prefix + '[' + key + ']', obj[key]);
      }
    } else {
      add(container, prefix, obj);
    }
  } else if (Array.isArray(obj)) {
    for (i = 0, len = obj.length; i < len; i++) {
      add(container, obj[i].name, obj[i].value);
    }
  } else {
    for (key in obj) {
      buildParams(container, key, obj[key]);
    }
  }
  return container;
}

function params(obj: string[]): string {
  return buildParams([], '', obj).join('&');
}

export {params};
